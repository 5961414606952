import React, { useState, useContext } from 'react';
import { UserContext } from '../contexts/UserContext'
import Wallet from './mas/Wallet';
import Account from './Account';
import BurgerIcon from '../assets/svg/Burger';
import ProfileIcon from '../assets/svg/Profile';
import WalletIcon from '../assets/svg/Wallet';
// import LoginIcon from '../assets/svg/Login';
import Logo from '../assets/svg/Dot';
import "./TopNav.scss";


function TopNav ({user, tags, setFilter}) {
  
  const wlsUser = useContext(UserContext);

  let msg = '';

  if (tags.length === 0) {
    msg = 'No tags so far';
  }

  const [menuToggle, setMenuToggle] = useState('off');
  const [profileToggle, setProfileToggle] = useState('off');
  const [walletToggle, setWalletToggle] = useState('off');

  const handleMenuToggle = () => {
    setMenuToggle(prev => prev === 'off' 
      ? 'on'
      : 'off'
    );
    setProfileToggle('off');
    setWalletToggle('off');
  }

  const handleProfileToggle = () => {
    setProfileToggle(prevToggle => prevToggle === 'off' 
      ? 'on'
      : 'off'
    );
    setMenuToggle('off');
    setWalletToggle('off');
  }
  
  const handleWalletToggle = () => {
    setWalletToggle(prevToggle => prevToggle === 'off' 
      ? 'on'
      : 'off'
    );
    setMenuToggle('off');
    setProfileToggle('off');
  }


  const toggleAll = () => {
    setMenuToggle('off');
    setProfileToggle('off');
    setWalletToggle('off');
  }


  const Stake = () => {

    return (
      wlsUser ? (
      <span className="stake">
        {wlsUser.stake}
      </span>
      ) : <></>
    )
  }


  const WalletBtn = () => {
    return (
      <span 
        className={`topnav__icon`}
        onClick={() => handleWalletToggle()}
      >
        <WalletIcon />
      </span>
    )
  }

  const ProfileBtn = () => {
    return (
      <span 
        className={`topnav__icon ${profileToggle}`}
        onClick={() => handleProfileToggle()}
      >
        <ProfileIcon />
      </span>
    )
  }
  const MenuBtn = () => {
    return (
      <span 
        className={`topnav__icon`}
        onClick={() => handleMenuToggle()}
      >
        <BurgerIcon />
      </span>
    )
  }

  const handleClickTerm = t => {
    setMenuToggle('off');
    setFilter(t);
  }

  const clearTerms = () => {
    setMenuToggle('off');
    setFilter('');
  };

  const Terms = ({ terms }) => {
    return (
      <div className="tag-list">
        {terms && terms.map ((t, x) => (
        <span 
          className="tag"
          onClick={ () => handleClickTerm(t) }
          key={x}
        >
          {t}
        </span>
        ))}
      </div>
    );
  }  

  return (
      <div className="TopNav">
        <div className="topbar">
          <span className="logo" onClick={toggleAll}>
            <Logo />
          </span>
          <Stake />
          <WalletBtn /> 
          <ProfileBtn />
          <MenuBtn />
        </div>  

        <div className={`menu ${menuToggle}`}>

          <span className="menu-item" onClick={() => clearTerms()}>
            All Items
          </span> 

          <span className="menu-subtitle">
            Items by Tags
          </span>

          {tags.length > 0 
            ? 
            <Terms terms={tags} />
            :  
            <div className="msg">
              {msg}
            </div>
          }
          
        </div>

        <div className={`profile-wrapper ${profileToggle}`}>
          <Account user={user} />
        </div>

        <div className={`wallet-wrapper ${walletToggle}`}>
          <Wallet />
        </div>

      </div>
  );
}

export default TopNav;