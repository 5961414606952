import React, { useEffect, useState } from 'react';
import { UserProvider } from '../contexts/UserContext';
import { supabase } from "../lib/api";
import UserLogin from './UserLogin';
import Main from './Main';

const App = () => {  

  (function () {
    document.body.dataset.theme = 'noir';
  })();

  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const s = supabase.auth.session();
    setSession(s ?? null);
    setUser(s?.user ?? null);

    const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          const currentUser = session?.user;
          setUser(currentUser ?? null);
        }
    );

    return () => {
        authListener?.unsubscribe();
    };
  }, [user]);

  return (
    <UserProvider>
      <div className="App">
        {!user ? <UserLogin /> : <Main user={user} session={session} />}
      </div>
    </UserProvider>
  );
}

export default App;