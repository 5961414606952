import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../lib/api";
import TopNav from './TopNav';
import SingleNote from './SingleNote';
import Edit from './Edit';

import "../index.css";
import "./Main.scss";

const NOTE = process.env.REACT_APP_NOTE_TABLE;


// main
function Main ( { user, handleUserLogOut } ) {

  const [errorData, setErrorData] = useState({
    message: '',
    component: ''
  });
  
  const [notes, setNotes] = useState([]);
  const [noteToEdit, setNoteToEdit] = useState();
  const [tags, setTags] = useState([]);
  const [filter, setFilter] = useState('');
  const [doneEditing, setDoneEditing] = useState(true);

  const handleClearTag = () => setFilter('');

  const err = useCallback( (component, error={}, message='') => {

    const msgLib = [
      {
        code: '23514',
        text: 'The note should be longer than 3 characters'
      }
    ];
      
    const defaultMsg = 'Something is wrong';
    let msg = '';
    if (error?.code) 
      msg = msgLib.filter(e => e.code === error.code).length > 0 ? msgLib.filter(e => e.code === error.code).length[0] : error.message + ' (' + error.code + ')';
    else 
      msg = message ? message : defaultMsg;

    setErrorData ({
      message: msg,
      component: component ? component : 'Edit'
    });
  }, []);

  const readNotes = useCallback (async () => {
    let { data: noteList, error } = await supabase
    .from(NOTE)
    .select("*")
    .order("id", { ascending: false });
    if (error) 
      return;
    let tagList = [];
    noteList.forEach( n => {
      if (n.tag && n.tag.length > 0) {
        n.tag.forEach(t => {
          if( !(tagList.includes(t)) )
            tagList.push(t);
        })
      }
    noteList.sort((x, y) => (x.pinned && y.pinned ? 0 : x.pinned ? -1 : 1));
    setNotes(noteList);
    });
    if (tagList)
      setTags (tagList);
  }, []);

  const updateNote = async (key, val, noteId) => {
    await supabase
    .from(NOTE)
      .update({ [key]: val })
      .eq(["id"], noteId)
      .single();
    await readNotes();

    setNoteToEdit();
    setDoneEditing(true);
  }

  const deleteNote = async (id) => {
    try {
      await supabase.from(NOTE).delete().eq("id", id);
      setNotes(notes.filter( n => n.id !== id));
    } catch (error) {
      console.log("error", error);
      err('Edit', error);
    }
  };

  const createNote = async (key, val) => {
    let { data: note, error } = await supabase
    .from(NOTE)
    .insert({ [key]: val, user_id: user.id })
    .single();
    if (error) {
      err('Edit', error);
      return;
    } else {
    setNotes([note, ...notes]);
    }
  };

  // eslint-disable-next-line
  useEffect(() => { readNotes() }, []);

  return (
    <div className={`Main`}> 
    
      <TopNav 
        handleUserLogOut={handleUserLogOut}
        setFilter={setFilter}
        notes={notes}
        tags={tags}
        user={user}
      />
      <Edit 
        createNote={createNote}
        updateNote={updateNote}
        noteToEdit={noteToEdit}
        setNoteToEdit={setNoteToEdit}
        doneEditing={doneEditing}
        setDoneEditing={setDoneEditing}
        setFilter={setFilter}
        errorData={errorData}
        setErrorData={setErrorData}
      />

      {filter !== '' && (
        <div className="filters">
          Tag: <span className="tag" onClick={() => handleClearTag()}>{filter}</span>
        </div>
      )}

      <section className="notelist">
        {filter ? (
          notes.filter(n => n.tag && n.tag.includes(filter)).map((note, idx) => (
          <SingleNote 
            key={idx} 
            id={note.id} 
            note={note} 
            notes={notes}
            deleteNote={deleteNote} 
            updateNote={updateNote}
            noteToEdit={noteToEdit} 
            setNoteToEdit={setNoteToEdit}
            setDoneEditing={setDoneEditing}
          />
        ))) : (
          notes.map((note, idx) => 
          <SingleNote 
            key={idx} 
            id={note.id} 
            note={note} 
            notes={notes}
            deleteNote={deleteNote} 
            updateNote={updateNote}
            noteToEdit={noteToEdit} 
            setNoteToEdit={setNoteToEdit}
            setDoneEditing={setDoneEditing}
          />
          ))
        }
      </section>
    </div>
  );
}

export default Main;