import { useState, useEffect, useRef } from 'react'
import { supabase } from "../lib/api";
import ProfileIcon from '../assets/svg/Profile';
import EditIcon from '../assets/svg/Edit';
import "./Account.scss";

export default function Account({ user }) {
  
  const [username, setUsername] = useState('')
  const [website, setWebsite] = useState('')

  async function getProfile() {
    try {
      const user = supabase.auth.user()

      let { data, error, status } = await supabase
        .from('profiles')
        .select(`username, website`)
        .eq('id', user.id)
        .single()

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setUsername(data.username)
        setWebsite(data.website)
      }
    } catch (error) {
      alert(error.message)
    }
  }

  async function updateProfile({ username, website }) {
    try {
      const user = supabase.auth.user()

      const updates = {
        id: user.id,
        username,
        website,
        updated_at: new Date(),
      }

      let { error } = await supabase.from('profiles').upsert(updates, {
        returning: 'minimal', // Don't return the value after inserting
      })

      if (error) {
        throw error
      }
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  const nRef = useRef();
  const wRef = useRef();
  const updRef = useRef();

  const enableEdit = (item) => {
    item.disabled = false;
  }

  return (
    <div className="Account">

      <span className="profile-icon">
        <ProfileIcon />
      </span>
      <span className="user-id">
        {user.email}
      </span>

      <div className="input-wrapper">
        <input
          ref={nRef}
          id="username"
          type="text"
          value={username || ''}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          disabled
        />
        <span className="inline-icon" onClick={ () => enableEdit(nRef.current)}>
          <EditIcon />
        </span>
      </div>

      <div className="input-wrapper">
        <input
          ref={wRef}
          id="website"
          type="website"
          value={website || ''}
          onChange={(e) => setWebsite(e.target.value)}
          placeholder="Link"
          disabled
        />
        <span className="inline-icon" onClick={ () => enableEdit(wRef.current)}>
          <EditIcon />
        </span>
      </div>

      <button
        ref={updRef}
        className="button--primary hide"
        onClick={() => updateProfile({ username, website })}
      >
        {'Update Profile'}
      </button>
      
      <button className="button--secondary" onClick={() => supabase.auth.signOut()}>
        Sign Out
      </button>

    </div>
  )
}