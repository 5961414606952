import React, { useState, useContext } from "react";
import { UserContext, UserUpdateContext } from "../../contexts/UserContext";
import DOMPurify from "dompurify";
import { getWlsAccount, GetWalletStats } from "./wlsMasApi";import './Wallet.scss';
import './KeytoolLogin.scss';


export default function KeytoolLogin() {
  const wlsUser = useContext(UserContext);
  const setWlsUser = useContext(UserUpdateContext);
  const [inputName, setInputName] = useState();

  const handleWlsLogin = async () => {
    let n = inputName?.trim();
    let accName = DOMPurify.sanitize(n);
    const account = await getWlsAccount(accName);
    if (account.name === accName) {
      let response = await window.whalevault?.promiseRequestPubKeys(
        "Dodapp",
        "wls:" + accName
      );

      if (response && response.success) {
        if (
          response.result["wls:" + accName].postingPubkey &&
          response.result["wls:" + accName].postingPubkey ===
            account.posting.key_auths[0][0]
        ) {
          const wlsData = await GetWalletStats(accName);
          wlsData?.username && setWlsUser({ ...wlsData });
          wlsData?.username &&
            window.localStorage.setItem("wlsUsername", wlsData.username);
        }
      }
    }
  };

  return (
    <div className="KeytoolLogin">
      {typeof window.whalevault === 'object' && wlsUser && wlsUser.username ? (
        <></>
      ) : (
        <div>
          <h3>Login to Wallet</h3>
          <input
            type="text"
            className="wls-username-input"
            value={inputName || ""}
            name={inputName}
            placeholder="WLS Username"
            aria-label="wls username"
            onChange={(e) => setInputName(e.currentTarget.value)}
          />
          <button className="button" onClick={() => handleWlsLogin()}>
            Wallet Login
          </button>
        </div>
      )}
    </div>
  );
}
