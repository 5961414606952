import React from 'react';
import "./KeytoolCheck.scss";

export default function KeytoolCheck () {
    
  const download = {
    chrome: 'https://chrome.google.com/webstore/detail/hcoigoaekhfajcoingnngmfjdidhmdon',
    firefox: 'https://addons.mozilla.org/en-US/firefox/addon/whalevault/'
  };

  return (
    <div className="KeytoolCheck">
      <div className="message">
        To use Wallet features, install WhaleVault browser extension. 
      </div>
      <div className="w-link">
        <a className="button chrome" href={download.chrome}>
          Chrome / Brave
        </a>
        <a className="button firefox" href={download.firefox}>
          Firefox
        </a>
      </div>
    </div>
  );
}

