import React, { useContext } from "react";
import { UserContext, UserUpdateContext } from "../../contexts/UserContext";
import './KeytoolLogout.scss';

export default function KeytoolLogout() {
  const wlsUser = useContext(UserContext);
  const setWlsUser = useContext(UserUpdateContext);

  const handleWlsLogout = () => {
    window.localStorage.setItem("wlsUsername", "");
    setWlsUser();
  };

  return (
    <div className="KeytoolLogout">
      {wlsUser && wlsUser.username ? (
        <div>
          <button className="button" onClick={() => handleWlsLogout()}>
            Wallet Logout
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
