export const rpcNode = 'https://pubrpc.whaleshares.io';


export async function getWlsAccount (accName) {

  let data = await fetch(rpcNode + '/database_api/get_accounts/' + btoa('[["' + accName + '"]]'))
    .then(response => response.json())
    .catch(error => console.error(error));
  
  let account = [];
  if (!(data && data.result.length > 0)) {
    return;
  }
  account = data.result[0];
  console.log(account);
  return account;
}

export const GetWalletStats = async (accName) => {

  // global props

  const global_props = await fetch(rpcNode + '/database_api/get_dynamic_global_properties/W10%3D')
    .then(response1 => response1.json())
    .catch(error => console.error(error));
  
  if (!global_props.result) 
    return;

  const vr_cycle = global_props.result.vr_cycle;
  if (!vr_cycle || vr_cycle === 0) 
    return;

  const total_vr_snaphost_payout = global_props.result.vr_snaphost_payout.split(' ')[0];
  const total_vr_snaphost_shares = global_props.result.vr_snaphost_shares.split(' ')[0];

  // user account
  
  const str = `${rpcNode}/database_api/get_accounts/${Buffer.from('[["' + accName + '"]]').toString('base64')}`;
  const data = await fetch( str ) 
    .then(response => response.json())
    .catch(error => console.error(error));
  
  if (!(data && data.result.length > 0)) {
    return;
  }
  let account = data.result[0];
  console.log(account);

  // calculating...

  let vr_snaphost_cycle = account.vr_snaphost_cycle;
  let vr_snaphost_next_cycle = account.vr_snaphost_next_cycle;
  
  let claimed = Number(account.vr_claimed_cycle === vr_cycle ? account.vr_claimed_amount.split(' ')[0] : 0);
  let vr_snaphost_shares = account.vr_snaphost_shares.split(' ')[0];
  let vesting_shares = account.vesting_shares.split(' ')[0];

  let account_vshares = 0;
  if (vr_snaphost_next_cycle < vr_cycle)
    account_vshares = vesting_shares;
  else if (vr_snaphost_cycle < vr_cycle)
    account_vshares = vr_snaphost_shares;

  let reward_pool = Math.floor(account_vshares / total_vr_snaphost_shares * total_vr_snaphost_payout * 1000) / 1000;

  // stats
 return {
    username: account.name,
    stake: Math.floor(parseFloat(account.vesting_shares.replace(" VESTS", ""))),
    liquid: Math.floor(parseFloat(account.balance.replace(" WLS", ""))),
    tipjar: Math.floor(parseFloat(account.reward_steem_balance.replace(" WLS", ""))),
    received: Math.floor(parseFloat(account.reward_vesting_balance.split(' ')[0])),
    dr: reward_pool - claimed,
  }
}

