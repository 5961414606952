import React, {useContext} from 'react';
import { UserContext } from '../../contexts/UserContext';
import KeytoolLogin from './KeytoolLogin';
import KeytoolLogout from './KeytoolLogout';
import KeytoolCheck from './KeytoolCheck';
import ProfileIcon from '../../assets/svg/Profile';
import './Wallet.scss';

export default function Wallet () {

  const wlsUser = useContext(UserContext);

  return (
    <div className="Wallet">

      {typeof window.whalevault === 'object' && wlsUser&& wlsUser.username ? (
        <>
          <span className="profile-icon">
            <ProfileIcon />
          </span>
          <span className="user-id">
            {wlsUser.username}
          </span>
                    
          <div className="wallet__stats">

            <div className="wallet__cards">

              <div className="card">
                <h3>Reward to Claim Today</h3>
                <var>DR</var>
              </div>

              <div className="card">
                <h3>Stake</h3>
                <var>{wlsUser?.stake}</var>
              </div>

              <div className="card">
                <h3>Liquid Balance</h3>
                <var>{wlsUser.liquid}</var>
              </div>

              <div className="card">
                <h3>Tip Jar</h3>
                <var>{wlsUser.tipjar}</var>
              </div>

              <div className="card">
                <h3>Received Tips</h3>
                <var>{wlsUser.received}</var>
              </div>

            </div>

          </div>
          <KeytoolLogout />
        </>
      ) : (
        !window.whalevault ? (
        <KeytoolCheck />
        ) : (
        <KeytoolLogin />
        )
      )}
    </div>
  )
}
