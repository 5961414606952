import React, { useState, useContext, useRef } from 'react';
import DOMPurify from 'dompurify';
import { UserContext, UserUpdateContext } from "../contexts/UserContext";
import { rpcNode } from "./mas/wlsMasApi";
import IconDone from '../assets/svg/Done';
import IconEdit from '../assets/svg/Edit';
import IconPin from '../assets/svg/Pin';
import IconSwitch from '../assets/svg/Restart';
import IconMark from '../assets/svg/InlineCheckMark';
import './SingleNote.scss';

const SingleNote = ( {note, updateNote, deleteNote, setNoteToEdit, setDoneEditing} ) => {

  const wlsUser = useContext(UserContext);
  const setWlsUser = useContext(UserUpdateContext);
  const noteRef = useRef();


  const [icon, setIcon] = useState('edit');
  const [termInput, setTermInput] = useState('');

  const switchButton = () => {
    const switchState = (state) => {
      switch (state) {
        case 'edit':
          return 'pin';
        case 'pin':
          return 'tag';
        case 'tag': 
          return 'edit';
        default:
          console.log('Something is wrong');
          break;
      }
    }
    setDoneEditing();
    setIcon(prev => switchState(prev));
  }

  const handleAddTag = ()    => {
    if (termInput.trim() !== '' ) {
      let t = DOMPurify.sanitize(termInput.trim());
      let tagArr = [];
      if (note.tag && note.tag.length > 0)
        tagArr = note.tag;
        tagArr.push(t);
      updateNote('tag', tagArr, note.id);
      setTermInput('');
    }
  }

  const handleReward = async (amount, noteId, elmRef, delay) => {
    //deleteNote(note.id);
    let theUser = wlsUser?.username;  
    let amount_formatted = `${Math.floor(parseInt(amount))}.000 WLS`;    
    let txop = [
      ['social_action',
        {
          account: theUser,
          action: [3, { amount: amount_formatted, to: theUser }],
        }
      ]
    ]; 
    window.whalevault?.requestSignBuffer('dodapp', 'wls:' + theUser,
    { url: rpcNode, operations: txop },
    'Posting', 'reward', 'tx',
    async function (response) {
      if (response.success) {
        console.log('success', response);
        
        let data = await fetch(rpcNode + '/database_api/get_accounts/' + btoa('[["' + theUser + '"]]'))
        .then(response => response.json())
        .catch(error => console.error(error));
      
        if (!(data && data.result.length > 0)) {
          return;
        }
        let account = data.result[0];
        const stake = Math.floor(parseFloat(account.vesting_shares.replace(" VESTS", ""))); 
        stake && setWlsUser(prev => {return {...prev, stake}});
    
        setTimeout(() => {
          deleteNote(noteId);
        }, delay);
      };
    });
  }

  const RewardBtn = () => {
    return (
      <span className="btn-icon reward"
        onClick={() => handleReward(note.reward, note.id, noteRef, 3000)}
      >
        {note.reward}
      </span>
    )
  }

  const Indicators = () => {
    return (
      <span className="buttons indicators">
        {note.reward ? <RewardBtn /> : <DoneBtn /> }
      </span>
    )
  }

  const DoneBtn = () => {
    return (
      <span 
        className="btn-icon done"
        onClick={() => deleteNote(note.id)}
      >
      <IconDone />
    </span>
    )
  }

  const Content = () => {
    return (
      <span className="content">
        {note.body}
      </span>
    )
  }

  return (
    <div className={`SingleNote`} id={note.id} ref={noteRef}>
      <Indicators />
      <Content />
      
      <span className="buttons actions">
        {icon === 'tag' && (
        <div className={`add-tag`}>
          <input 
            type="text" 
            placeholder="Add tag" 
            value={termInput} 
            onChange={evt => setTermInput(evt.currentTarget.value)} 
          />
          <span className="inline-icon" onClick={() => handleAddTag()} >
            <IconMark onClick={() => handleAddTag()} />
          </span>
        </div>
        )}

        {icon === 'pin' && (
        <span 
          className={`btn-icon pin`}
          onClick={() => updateNote('pinned', 1, note.id) }
        >
          <IconPin />
        </span>
        )}

        {icon === 'edit' && (
        <span 
          className={`btn-icon edit`}
          onClick={() => { setNoteToEdit(note); setDoneEditing(false) } }
        >
          <IconEdit />
        </span>
        )}

        <span 
          className={`btn-icon switch`}
          onClick={switchButton}
        >
          <IconSwitch />
        </span>

      </span>

    </div>
  );
}

export default SingleNote;