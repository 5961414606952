import React, {useRef, useState, useEffect} from 'react';
import { supabase } from "../lib/api";
import DOMPurify from 'dompurify';
import './UserLogin.scss';


const UserLogin = () => {

  const [msg, setMsg] = useState({ error: '', code: null, text: '' });
  
  const emailRef = useRef();

  const handleKey = evt => {
    if (evt.key === 'Enter' && emailRef.current?.value.trim().length > 0) 
      handleLoginWithLink();
  }
  
  const handleLoginWithLink = async (e) => {

    if ( emailRef.current?.value === '' ) {
      setMsg({
        error: true,
        code: 1,
        text: "You must enter your email address.",
      });
      return;
    }

    let email = DOMPurify.sanitize(emailRef.current?.value.trim());
    try {
      const { error } = await supabase.auth.signIn({ email });
      console.log (error);

      if (error) 
        throw error;
        setMsg({
          error: true,
          code: 0,
          text: "Check your email",
        });
      } catch (error) {
        setMsg({
          error: true,
          code: 0,
          text: error.message,
        });
    }
  }


  useEffect( () => { 

    const timer = setTimeout(() => {
      setMsg ({ text: '', code: null, error: false });
    }, 5000);
    return () => {
      clearTimeout(timer);
    };

  }, [msg]);

  const bgColor = '#fe0';

  const Notifications = ({text}) => {
    return (
      <div className="notifications">
        <div className="wrapper"
            style={{ backgroundColor: msg?.text && bgColor }}>
          {text}
        </div>
      </div>
    );
  }

  return (
    <div className={`UserLogin`}>
      <div className="main">
        <Notifications 
          text={msg.text}
        />
        <div className="login-area">
        <div className="login__secondary">
            <h1 className="logo clip-text clip-text_two">
              Do.
            </h1>
          <h2 className="tagline">
            A Feature-Free To-Do App
          </h2>
        </div>

        <div className="login__primary">
          <div className="bg">
            <h1 className="logo clip-text clip-text_two">
              Do.
            </h1>
          </div>
          <div className="form">
            <h3>Login</h3>
            <input className="input-email"
              name={`do-email`}
              ref={emailRef}
              onKeyDown={evt => handleKey(evt)}
              required
              placeholder="Email"  
              style={{ backgroundColor: msg?.code === 1 && bgColor }}
            />
            <span className="hint">Login (or signup) with your email only. No crazy passwords to care for — a magic link will get you right to the app.</span>
            <span className="wrapper">
              <input
                type="submit"
                className="login__button"
                onClick={ () => handleLoginWithLink() }
                value="Get Login Link"
              />
            </span>
          </div>

        </div>
        </div>
      </div>

    </div>
  );
}

export default UserLogin;
