
import React, { createContext, useEffect, useState } from "react";
import {GetWalletStats} from '../components/mas/wlsMasApi';

const UserContext = createContext(undefined);
const UserUpdateContext = createContext(undefined);

function UserProvider({ children }) {
  const [wlsUser, setWlsUser] = useState({
    username: '',
    stake: '',
    liquid: '',
    tipjar: '',
    received: '',
    dr: '',
  });

  useEffect ( () => {
    ( async () => {
      let wlsUsername = window.localStorage.getItem('wlsUsername');
      if (wlsUsername) {
        const wlsData = await GetWalletStats(wlsUsername);
        setWlsUser ({...wlsData});
      }
    })();
  }, []);

  return (
    <UserContext.Provider value={wlsUser}>
      <UserUpdateContext.Provider value={setWlsUser}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserUpdateContext };