import React, { useState, useEffect, useRef } from 'react';
import DOMPurify from  'dompurify';
import IconAdd from '../assets/svg/Checkmark44';
import IconErr from '../assets/svg/Error';
import IconMark from '../assets/svg/InlineCheckMark';
import './Edit.scss';

export default function Edit ( { errorData, setErrorData, createNote, updateNote, noteToEdit, doneEditing = true, setDoneEditing, setFilter} ) {

  const [body, setBody] = useState(noteToEdit ? noteToEdit.body : '');
  const [reward, setReward] = useState(0);
  const [termInput, setTermInput] = useState('');
  const [fn, setFn] = useState('');
  
  const editFn = (args) => {
    fn === 'create' 
      ? createNote(...args)
      : updateNote(...args);
  }

  const bodyArea = useRef();

  const handleSubmitBody = () => {
    let b = '';
    if (body !== '' ) {
      b = DOMPurify.sanitize(body.trim());
      if (b.length > 3) {
        let args = ['body', b, noteToEdit?.id];
        editFn(args);
        setBody('');
      } else if (b.length > 0) {
        setErrorData ({
          message: 'Only notes with more than 3 letters can be kept.',
          component: 'Edit'
        });
      }
    }
  }

  const handleClickTerm = t => {
    setFilter(t);
  }

  const handleAddTag = ()    => {
    if (noteToEdit && termInput.trim() !== '' ) {
      let t = DOMPurify.sanitize(termInput.trim());
      let tagArr = [];
      if (noteToEdit.tag && noteToEdit.tag.length > 0)
        tagArr = noteToEdit.tag;
      tagArr.push(t);
      updateNote('tag', tagArr, noteToEdit.id);
      setTermInput('');
    }
  }

  const handleKeySubmit = evt => {
    if (evt.key === 'Enter' && !evt.shiftKey && typeof body === 'string' && body.trim().length > 0) 
      handleSubmitBody();
  }
  const handleKeyReward = e => {
    if (e.key === 'Enter' && !e.shiftKey && typeof body === 'string' && body.trim().length > 0) 
      updateNote('reward', e.currentTarget.value, noteToEdit.id);
      setReward('');
  }
  const handleKeyTag = e => {
    if (e.key === 'Enter' && !e.shiftKey && typeof body === 'string' && body.trim().length > 0) 
      handleAddTag();
  }


  useEffect(() => {
    setBody( noteToEdit ? noteToEdit.body : bodyArea.current.value.trim() ? bodyArea.current.value : '');
    setFn (noteToEdit ? 'update' : 'create' );

    const timer = setTimeout(() => {
      setErrorData (prev => prev.component === 'Edit' && {message: '', component: '' });
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [noteToEdit, setErrorData]);

  return (
    <div className={`Edit`}>
      <textarea 
        className="editor"
        ref={bodyArea}
        value={ !doneEditing ? body : ''}
        onChange={() => {setDoneEditing(false); setBody(bodyArea.current.value)} }
        onKeyDown={evt => handleKeySubmit(evt)}
      />
      {!doneEditing && (
        <div className="toolbar">
          {errorData && errorData.component === 'Edit' && errorData.message ? (
            <div className="msg">
              <span className="inline-icon">
                <IconErr/>
              </span>
              {errorData.message}
            </div>
          ) : (
            noteToEdit && body?.length > 3 && (
              <div className="note-tags">
                <input 
                  className="reward"
                  name="reward"
                  value={reward}
                  onChange={e => setReward(e.currentTarget.value)}
                  onBlur={e => updateNote('reward', e.currentTarget.value, noteToEdit?.id)}
                  onKeyDown={e => handleKeyReward(e)}
                />
                <div className={`add-tag`}>
                  <input 
                    type="text" 
                    placeholder="Add tag" 
                    value={termInput} 
                    onChange={e => setTermInput(e.currentTarget.value)} 
                    onKeyDown={e => handleKeyTag(e)}
                    onBlur={e => updateNote('reward', e.currentTarget.value, noteToEdit?.id)}
                  />
                  <span className="inline-icon" onClick={() => handleAddTag()} >
                    <IconMark />
                  </span>
                </div>
              </div>
            ))}
            {noteToEdit && noteToEdit.tag && noteToEdit.tag.length !== 0 && (
              noteToEdit.tag.map( (tag, idx) => (
                <span 
                  className="tag" 
                  key={idx}
                  onClick={ () => handleClickTerm(tag) }
                >
                {tag}
              </span>
            )))}
            <div className="buttons">
              <span 
                className={`btn add`} 
                onClick={handleSubmitBody}
              >
              <IconAdd />
            </span>
          </div>
        </div>
      )}
    </div>
  ); 
}